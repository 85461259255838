import type { CustomCart } from "~/server/utils/mapping/cartMapping";

export async function useCartLoader(options: { immediate: boolean }) {
  const cart = useCart();
  const error = ref(null as any);
  const pending = ref(false);

  const cartId = await useCartId();

  const fetchData = async () => {
    pending.value = true;

    try {
      await $fetch<CustomCart>("/api/ecom/cart", {
        query: { cartId: cartId.value },
        onResponse: ({ response }) => {
          if (response.status >= 200 && response.status < 300) {
            cart.value = response._data as CustomCart;

            if (!cartId.value) {
              // might be cart from another session
              cartId.value = cart.value.cartId;
            }

            if (cart.value.postageData?.shippingMethod === "") {
              cart.value.postageData.shippingMethod = "delivery";
            }
          }
        },
        onResponseError: ({ response }) => {
          error.value = response;
        },
      });
    }
    finally {
      pending.value = false;
    }
  };

  if (options.immediate) {
    await fetchData();
  }

  return {
    cart: cart,
    error: error,
    pending: pending,
    refreshCart: fetchData,
  };
}
