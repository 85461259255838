export async function useCartId() {
  const cartId = useState("cart-id", () => ref<string | null>(null));

  const { loggedIn } = useUserSession();
  if (loggedIn.value) {
    await callOnce(async () => {
      // runs on server
      const headers = useRequestHeaders(["cookie"]);
      const existingId = await $fetch("/api/ecom/cart/current-id", { headers: headers });

      if (existingId) {
        cartId.value = existingId;
      }
    });
  }

  return cartId;
}
